<template>
    <div class="text-left">
      <h3 class="f-bold cl-primary mb-4">Update My Account</h3>
      <form class="mt-4" @submit.prevent="updateProfile">
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="text"
              class="form-control b-radius px-4"
              id="name"
              placeholder="Name"
              v-model="form.name"
              required
            />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="email"
              class="form-control b-radius px-4"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Email"
              v-model="form.email"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="number"
              class="form-control b-radius px-4"
              id="phone"
              aria-describedby="emailHelp"
              placeholder="Phone Number"
              v-model="form.telephone"
              required
            />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <select
              class="form-control b-radius px-3"
              id="gender"
              v-model="form.gender"
              required
            >
              <option selected disabled>Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div class="form-group">
              <!-- <label for="exampleFormControlTextarea1">Example textarea</label> -->
              <textarea
                class="form-control b-radius px-3"
                id="address"
                rows="3"
                placeholder="Address "
                v-model="form.address"
                required
              ></textarea>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <input
              type="text"
              class="form-control b-radius px-4"
              id="username"
              aria-describedby="emailHelp"
              placeholder="Username"
              v-model="form.username"
              required
            />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="!loading">
          <button type="submit" class="btn btn-success px-5">Save</button>
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="loading">
          <loader-component></loader-component>
        </div>
      </form>
    </div>
  </template>
  <script>
  import Vue from "vue";
  export default {
    name: "MyAccountAdminForm",
    data() {
      return {
        loading: false,
        form: {
          name: "",
          email: "",
          telephone: "",
          gender: "",
          address: "",
          password: "",
          username: "",
          password_confirmation: "",
        },
      };
    },
    computed: {
      userLogin: function () {
        return this.$store.getters.getProfileCustomers;
      },
    },
    mounted() {
      this.setForm();
    },
    methods: {
      setForm() {
        this.form.name = this.userLogin.name;
        this.form.email = this.userLogin.email;
        this.form.telephone = this.userLogin.telephone;
        this.form.gender = this.userLogin.gender;
        this.form.address = this.userLogin.address;
        this.form.username = this.userLogin.username;
      },
  
      updateProfile() {
        this.loading = true;
        const endpoint = "profile-update";
        this.$api.post(endpoint, this.form, (status, data, message) => {
          if (status === 200) {
            this.loading = false;
            console.log(data);
            Vue.swal(
              "Success",
              "Profile status has been successfully updated",
              "success"
            ).then(() => {
              this.$router.push("/admin/my-account");
            });
          } else {
            this.loading = false;
            console.log(message);
          }
        });
      },
    },
  };
  </script>
  